import LogoDatacie from '@src/assets/logo-datacie.svg';
import LogoQuisir from '@src/assets/logo-quisir.svg';
import LogoQuisirSmall from '@src/assets/logo-quisir-image-dark-only.svg';
import '@src/assets/main.css';
import React, { useState, ReactNode, ReactElement } from 'react';
import CloseButton from './CloseButton';
import { Logo, Theme, ActionItem } from '@src/types';
import { SimpleButton } from '@src/Button';
import { Transition } from '@tailwindui/react';

export type Props = {
  menuItems?: ActionItem[];
  menuItemsLinks?: ReactNode[];
  cta?: ActionItem;
  logo?: Logo;
  theme?: Theme;
  isCTADisplayed?: boolean;
  isSeparatorDisplayed?: boolean;
  isFixed?: boolean;
};

const HeaderMenu = ({
  menuItems,
  menuItemsLinks,
  cta,
  logo,
  theme,
  isCTADisplayed,
  isSeparatorDisplayed,
  isFixed,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${
        isFixed ? 'fixed top-0 w-full' : 'relative'
      } bg-lightgrey z-40`}
    >
      <div
        className={
          'py-4 h-24 flex justify-between items-center px-4 sm:px-6 lg:px-6 md:justify-start md:space-x-10'
        }
      >
        <a href="/">
          {(logo === Logo.Datacie || logo === undefined) && (
            <LogoDatacie
              className="cursor-pointer h-8 w-auto sm:h-10"
              alt="Datacie"
            />
          )}
          {logo === Logo.Quisir && (
            <>
              <LogoQuisirSmall
                className="hidden md-only:block cursor-pointer h-8 w-auto sm:h-10"
                alt="Quisir"
              />
              <LogoQuisir
                className="block md-only:hidden cursor-pointer h-8 w-auto sm:h-10"
                alt="Datacie"
              />
            </>
          )}
        </a>
        <div className="-mr-2 -my-2 md:hidden">
          <div
            onClick={() => setOpen(true)}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-grey hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-grey transition duration-150 ease-in-out cursor-pointer"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
        </div>
        <div className="hidden md:justify-end md:flex-1 md:flex md:items-center md:space-x-12">
          <nav className="flex space-x-8 lg:space-x-12">
            {menuItems &&
              menuItems.map(({ text, action }, i) => (
                <div
                  key={i}
                  onClick={action as any}
                  className="truncate heading text-grey cursor-pointer no-underline border-b-2 hover:border-darkturquoise-500 border-transparent"
                >
                  {text}
                </div>
              ))}
            {menuItemsLinks &&
              menuItemsLinks.map((link, i) => (
                <div
                  key={i}
                  className="heading text-grey no-underline border-b-2 hover:border-darkturquoise-500 border-transparent truncate cursor-pointer"
                >
                  {link}
                </div>
              ))}
          </nav>
          {cta && (
            <Transition
              show={!!isCTADisplayed}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <SimpleButton
                text={cta.text}
                id="header.cta"
                action={cta.action}
                theme={theme}
                uppercase={true}
                fullWidth={false}
                size="md"
              />
            </Transition>
          )}
        </div>
      </div>
      {open && (
        <div className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-lightgrey divide-y-2 divide-gray-50">
              <div className="p-5 space-y-8">
                <div className="flex items-center justify-between">
                  {(logo === Logo.Datacie || logo === undefined) && (
                    <LogoDatacie className="h-10 w-auto" alt="Datacie" />
                  )}
                  {logo === Logo.Quisir && (
                    <LogoQuisir className="h-10 w-auto" alt="Quisir" />
                  )}
                  <div className="-mr-2">
                    <CloseButton onClick={() => setOpen(false)} />
                  </div>
                </div>
                <nav className="flex flex-col space-y-4 mx-4">
                  {menuItems &&
                    menuItems.map(({ text, action }, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setOpen(false);
                          action();
                        }}
                        className="pt-2 truncate heading text-grey cursor-pointer"
                      >
                        {text}
                      </div>
                    ))}
                  {menuItemsLinks &&
                    menuItemsLinks.map((link, i) => (
                      <div
                        key={i}
                        className="pt-2 truncate heading text-grey cursor-pointer"
                      >
                        <div
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          {React.cloneElement(link as ReactElement, {
                            className: 'inline-block w-full h-full',
                          })}
                        </div>
                      </div>
                    ))}
                </nav>
                {cta && (
                  <div className="flex justify-center mt-6">
                    <SimpleButton
                      text={cta.text}
                      action={() => {
                        setOpen(false);
                        cta.action();
                      }}
                      theme={theme}
                      uppercase={true}
                      fullWidth={false}
                      size="md"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition
        show={!!isSeparatorDisplayed}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full border-b border-midgrey" />
      </Transition>
    </div>
  );
};

HeaderMenu.defaultProps = {
  isCTADisplayed: true,
  isSeparatorDisplayed: true,
  isFixed: false,
  size: 'normal',
};

export default HeaderMenu;
