import { capPosition, isAbove } from '@src/lib/coordinates';
import {
  Highlight,
  HighlightAction,
  PopupState,
  SelectionAction,
} from '@src/types';
import { isMatch } from 'lodash';

const sortNested = (a: Highlight, b: Highlight): number => {
  // If on the same page
  if (a.position.pageNumber === b.position.pageNumber) {
    return isAbove(a.position.boundingRect, b.position.boundingRect) ? -1 : 1;
  }
  return 0;
};

export const highlightsReducer = (
  state: Highlight[],
  action: HighlightAction
): Highlight[] => {
  switch (action.type) {
    case 'set':
      return [...action.newState];

    case 'add':
      return [...state, action.update].sort(sortNested);

    case 'delete':
      return state.filter(h => !action.ids.includes(h.id)).sort(sortNested);

    case 'update':
      return state
        .reduce((acc: Highlight[], h: Highlight) => {
          if (action.ids.includes(h.id) && !isMatch(h, action.update)) {
            acc.push({
              ...h,
              ...action.update,
            });
          } else {
            acc.push(h);
          }
          return acc;
        }, [])
        .sort(sortNested);
    case 'move':
      return state
        .map(h => {
          if (action.ids.includes(h.id)) {
            const targetX = h.position.boundingRect.x + action.x;
            const targetY = h.position.boundingRect.y + action.y;

            const [x, width] = capPosition(
              targetX,
              h.position.boundingRect.width
            );
            const [y, height] = capPosition(
              targetY,
              h.position.boundingRect.height
            );

            const boundingRect = {
              x,
              y,
              width,
              height,
            };
            return {
              ...h,
              position: {
                ...h.position,
                boundingRect,
              },
            };
          } else {
            return h;
          }
        })
        .sort(sortNested);
    case 'resize':
      return state
        .map(h => {
          if (action.ids.includes(h.id)) {
            const [x, width] = capPosition(
              h.position.boundingRect.x,
              h.position.boundingRect.width + action.x
            );
            const [y, height] = capPosition(
              h.position.boundingRect.y,
              h.position.boundingRect.height + action.y
            );

            const boundingRect = {
              x,
              y,
              width,
              height,
            };
            return {
              ...h,
              position: {
                ...h.position,
                boundingRect,
              },
            };
          } else {
            return h;
          }
        })
        .sort(sortNested);

    case 'duplicate':
      let i = -1;
      const getNextId = () => {
        i++;
        return action.newIds[i];
      };

      return state
        .reduce((acc: Highlight[], h) => {
          acc.push(h);

          if (action.ids.includes(h.id) && h.position.rects.length === 0) {
            acc.push({
              ...h,
              id: getNextId(),
              position: {
                rects: [],
                boundingRect: {
                  ...h.position.boundingRect,
                  x: h.position.boundingRect.x + 0.02,
                  y: h.position.boundingRect.y + 0.02,
                },
                pageNumber: h.position.pageNumber,
              },
            });
          }

          return acc;
        }, [])
        .sort(sortNested);
  }
};

export const selectionReducer = (state: string[], action: SelectionAction) => {
  switch (action.type) {
    case 'clear':
      return [];

    case 'toggle':
      const { id, e } = action;
      const alreadyClicked = state.includes(id);

      if (e.shiftKey) {
        if (alreadyClicked) {
          return state.filter(s => s !== id);
        } else {
          return [...state, id];
        }
      } else if (state.length === 1 && alreadyClicked) {
        return [];
      } else {
        return [id];
      }

    case 'force-select':
      return Array.from(new Set([...state, ...action.ids]));
  }
};

type PopupAction =
  | { type: 'close' }
  | {
      type: 'show';
      highlight: Highlight;
    }
  | {
      type: 'edit';
      highlight: Highlight;
    };

export const popupReducer = (state: PopupState, action: PopupAction) => {
  switch (action.type) {
    case 'close':
      return {
        ...state,
        edit: false,
        open: false,
      };

    case 'show':
      return {
        open: true,
        edit: false,
        highlight: action.highlight,
      };

    case 'edit':
      return {
        open: true,
        edit: true,
        highlight: action.highlight,
      };
  }
};
