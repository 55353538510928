import React, { HTMLAttributes, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { BoundingBox, Highlight } from '@src/types';
import { DocumentContext } from '../Document/Context';

type Props = {
  rects: BoundingBox[];
  onClick?: (e: React.MouseEvent) => void;
  onMouseOut?: () => void;
  isFocused: boolean;
  highlight: Highlight;
} & any;

/**
 * Styling
 */

const StyledHighlight = styled.div`
  position: absolute;
  /* pointer-events: none; */

  .parts {
    opacity: 1;
    .part {
      cursor: pointer;
      position: absolute;
      opacity: ${({ focused }: { focused: boolean }) =>
        focused ? '0.8' : '0.4'};
      background-color: rgb(255, 226, 143);
      transition: background 0.3s;
      ${({ focused }: { focused: boolean }) =>
        focused
          ? //'border-left: 3px solid #1C6EA4;border-right: 3px solid #1C6EA4;'
            'border-bottom: 3px solid #1C6EA4;'
          : ''}
    }
  }
`;

/**
 * Component
 */

export const TextHighlight = ({
  rects,
  // onClick,
  isFocused,
  highlight,
  ...rest
}: Props) => {
  const {
    styleHighlight,
    dispatchTooltipState,
    dispatchSelection,
  } = useContext(DocumentContext);

  const style = useMemo(() => {
    if (styleHighlight) {
      return styleHighlight(highlight);
    }
  }, [highlight, styleHighlight]);

  const onClick = (e: React.MouseEvent) => {
    dispatchSelection({
      type: 'toggle',
      id: highlight.id,
      e: e.nativeEvent,
    });

    dispatchTooltipState({
      type: 'edit',
      highlight,
    });
  };

  return (
    <StyledHighlight focused={isFocused} {...rest}>
      <div className="parts">
        {rects.map((rect: BoundingBox, index: number) => (
          <div
            onClick={onClick}
            key={index}
            style={{
              top: rect.y,
              left: rect.x,
              pointerEvents: 'all',
              ...rect,
              ...style,
            }}
            className="part"
          />
        ))}
      </div>
    </StyledHighlight>
  );
};
