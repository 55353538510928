import React, { ReactNode } from 'react';
import '@src/assets/main.css';
import { SimpleButton } from '@src/Button';
import { HeroCentered } from '@src/Hero';
import { Size } from '../../types';

export type ActionItem = {
  text: string;
  action: () => void;
};

export type Props = {
  id?: string;
  title: string | ReactNode;
  content?: string;
  subtitle?: string;
  size?: Size;
  backgroundColor?: string;
  actionLeft: ActionItem;
  actionRight?: ActionItem;
};

const CTA = ({
  id,
  title,
  content,
  subtitle,
  size,
  backgroundColor,
  actionLeft,
  actionRight,
}: Props) => {
  const children = (
    <div className="flex justify-center">
      <SimpleButton
        id={id ? `${id}.left` : ''}
        text={actionLeft.text}
        action={actionLeft.action}
        fullWidth={false}
        size="md"
        uppercase
        theme={{ variant: 'gradient-cta' }}
      />
      {actionRight && (
        <SimpleButton
          id={id ? `${id}.right` : ''}
          text={actionRight.text}
          action={actionRight.action}
          fullWidth={false}
          size="md"
          theme={{ variant: 'gradient-light' }}
        />
      )}
    </div>
  );

  return (
    <HeroCentered
      title={title}
      content={content}
      subtitle={subtitle}
      size={size}
      backgroundColor={backgroundColor}
      children={children}
    />
  );
};

export default CTA;
