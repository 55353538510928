// Taken from https://github.com/niekert/use-controlled-state

import React, { useState, useCallback, Reducer, useReducer } from 'react';
import { State } from 'react-select/src/Select';

// Either use the callback `setState` signature or just a "void"
// type OnChange<T> = (v: React.SetStateAction<T>) => T | void;
// type StateUpdater<T> = (v: T) => T;

function useControlledReducer(
  reducer: Reducer<any, any>,
  value: any,
  controlledDispatch: any = undefined
) {
  if (typeof value == 'function') {
    throw new TypeError(
      'Functions are not supported as state values in the `useControlledReducer` hook.'
    );
  }

  const [uncontrolledValue, uncontrolledDispatch] = useReducer(reducer, value);

  //   const onControlledChange = useCallback(
  //     (dispatch: any) => {
  //       if (controlledDispatch) {
  //         let changeValue: TState | StateUpdater<TState> = setStateArgument;
  //         if (typeof changeValue === 'function') {
  //           changeValue = (setStateArgument as StateUpdater<TState>)(value);
  //         }
  //         dispatch(changeValue);
  //       }
  //     },
  //     [value, dispatch]
  //   );

  // console.log(typeof controlledDispatch);

  if (typeof controlledDispatch === 'function') {
    // Controlled version
    return [value, controlledDispatch];
  }

  // Uncontrolled version
  return [uncontrolledValue, uncontrolledDispatch];
}

export default useControlledReducer;
