import { BoundingBox } from '../types';
const getBoundingRect = (boxes: BoundingBox[] | undefined) => {
  if (!boxes || boxes.length === 0) {
    return;
  }
  const { x0, x1, y0, y1 } = boxes.reduce(
    (max: { x0: number; x1: number; y0: number; y1: number }, bb) => {
      if (bb.x < max.x0) {
        max.x0 = bb.x;
      }

      if (bb.y < max.y0) {
        max.y0 = bb.y;
      }

      const x1 = bb.x + bb.width;
      const y1 = bb.y + bb.height;

      if (x1 > max.x1) {
        max.x1 = x1;
      }

      if (y1 > max.y1) {
        max.y1 = y1;
      }
      return max;
    },
    { x0: Infinity, y0: Infinity, y1: 0, x1: 0 }
  );

  return {
    x: x0,
    y: y0,
    width: x1 - x0,
    height: y1 - y0,
  };
};

export default getBoundingRect;
