export enum Logo {
  Quisir,
  Datacie,
}

export type Color =
  | 'gradient-cta'
  | 'gradient-light'
  | 'funblue'
  | 'lochmara'
  | 'darkturquoise'
  | 'aquamarine';

export type Size = 'sm' | 'md';

export type Theme = {
  dark?: boolean;
  variant?: Color;
};

export type ActionItem = {
  text: string;
  action: () => void;
  theme?: Theme;
};

export type UserTarget = 'Time series' | 'Contextual search' | 'Quisir';
