import React, { ReactNode } from 'react';
import '@src/assets/main.css';
import { Size } from '@src/types';

export type Props = {
  title: string | ReactNode;
  content?: string;
  subtitle?: string;
  size?: Size;
  backgroundColor?: string;
  children?: ReactNode | undefined;
};

const HeroCentered = ({
  title,
  content,
  subtitle,
  size,
  children,
  backgroundColor,
}: Props) => {
  return (
    <div
      style={{ backgroundColor }}
      className={`${backgroundColor ? '' : 'bg-transparent'} `}
    >
      <div className="overflow-hidden">
        <div className="inset-y-0 h-full w-full">
          <div className="mt-16 mx-auto px-4 sm:px-6 md:mt-10">
            <div className="text-center">
              {subtitle && (
                <div className="mb-6 uppercase tracking-wide heading text-grey">
                  {subtitle}
                </div>
              )}
              <h2
                className={`text-gray ${size === 'sm' ? 'sub-hero' : 'hero'}`}
              >
                {title}
              </h2>
              {content && <p className="text-gray mt-6 title">{content}</p>}
            </div>
          </div>
          <div className="mx-auto px-4 mt-12 sm:px-6">
            <div className="mx-auto w-full rounded-lg">
              {children && children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroCentered.defaultProps = {
  size: 'md',
};

export default HeroCentered;
