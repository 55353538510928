import { getOffset, isContained } from '@src/lib/coordinates';
import { getId } from '@src/lib/generic';
import { BoundingBox, GhostArea, Highlight } from '@src/types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { DocumentContext } from '../Document/Context';
import { AreaHighlight } from '../AreaHighlight';
import { popupReducer } from './reducers';
import { TextHighlight } from '../TextHighlight';

/**
 * Types definitions
 */

type Props = {
  scaler: any;
  rotate: number;
  pageNumber: number;
};

type ExtraLayerProps = {
  focused: boolean;
};

/**
 * Styling
 */

const StyledHighlightLayer = styled.div<ExtraLayerProps>`
  position: absolute;
  left: 0;
  top: 0;
  color: transparent;
  z-index: 13;
  // visibility: ${({ hide }: any) => (hide ? 'hidden' : 'visible')};
  ${({ focused }: any) => (focused ? 'right: 0;bottom:0;' : '')}
`;

const StyledSelection = styled.div`
  position: absolute;
  background: pink;
  opacity: 0.3;
`;

/**
 * Component
 */

export const HighlightLayer = ({ scaler, rotate, pageNumber }: Props) => {
  // The ghost area is the temporary selection by a user with alt + left click
  const [ghostArea, setGhostArea] = useState<GhostArea | undefined>();
  // Keep trace if the user released alt (he should still be able to select with
  // a ghost area)
  const [releasedAlt, setReleasedAlt] = useState(false);
  // // Reducer for the popup state
  // const [tooltipState, dispatchTooltipState] = useReducer(popupReducer, {
  //   edit: false,
  //   open: false,
  // });

  const pageRef = useRef<HTMLDivElement | null>(null);

  // Retrieve values from the context
  const {
    highlightsPerPage,
    selectedHighlights,
    dispatchSelection,
    setFocus,
    ghostHighlight,
    setGhostHighlight,
    dispatchHighlights,
    focus,
    Tooltip,
    TooltipHover,
    tooltipState,
    dispatchTooltipState,
  } = useContext(DocumentContext);

  // Get the highlights for the corresponding page
  const highlights = highlightsPerPage[pageNumber] ?? [];

  const focused = focus === 'highlight';

  // Reset the focus and close the popup whenever there are no
  // or more than 1 selected highlights
  useEffect(() => {
    if (
      selectedHighlights.length !== 1 &&
      !ghostHighlight &&
      tooltipState.edit
    ) {
      dispatchTooltipState({ type: 'close' });
    }
  }, [selectedHighlights, dispatchTooltipState, tooltipState, ghostHighlight]);

  // Used to keep in state the fact that the alt key was released but force the focus
  // on "highlight" to allow the user to keep on selecting
  useEffect(() => {
    if (
      focus === 'text' &&
      ghostArea &&
      ghostArea.width &&
      ghostArea.width > 0.005 &&
      ghostArea.height &&
      ghostArea.height > 0.005
    ) {
      setReleasedAlt(true);
      setFocus('highlight');
    }
  }, [focus, setFocus, ghostArea, setGhostArea]);

  // Given the area selected with alt + left click, decide
  // to create a highlight or to select some boxes
  const selectOrCreateGhostArea = useCallback(
    (ghostArea: GhostArea) => {
      setGhostArea(undefined);
      const ghostAreaDownscaled = scaler.downscale(ghostArea);

      // Get highlights contained by the ghost area
      const idsContained = highlights.reduce(
        (acc: string[], { id, position: { boundingRect } }) => {
          if (isContained(ghostAreaDownscaled, boundingRect)) {
            acc.push(id);
          }
          return acc;
        },
        []
      );

      // If some are contained, select them, else create a bounding rect
      if (idsContained.length > 0) {
        dispatchSelection({ type: 'force-select', ids: idsContained });
      } else if (dispatchHighlights) {
        const id = getId();
        const boundingRect = scaler.downscale(ghostArea);

        // Minimal acceptable size for the rectangle
        if (boundingRect.width < 0.005 || boundingRect.height < 0.005) {
          return;
        }

        const update = {
          position: {
            rects: [],
            pageNumber,
            boundingRect,
          },
          content: {
            image: 'thisisanimage',
          },
          comment: {
            text: '',
            tags: [],
          },
          id,
        };

        dispatchHighlights({ type: 'add', update });
        dispatchSelection({ type: 'clear' });
        dispatchSelection({ type: 'force-select', ids: [id] });

        dispatchTooltipState({
          type: 'edit',
          highlight: update,
        });
      }

      // If the user released the alt key, we forced the focus on
      // the highlight layer if a ghostArea was already created.
      // We have to reset it
      if (releasedAlt) {
        setFocus('text');
        setReleasedAlt(false);
      }
    },
    [
      scaler,
      highlights,
      dispatchHighlights,
      releasedAlt,
      dispatchSelection,
      pageNumber,
      dispatchTooltipState,
      setFocus,
    ]
  );

  // Tooltip that appears on hover
  const tooltipHover = useMemo(() => {
    if (
      TooltipHover &&
      tooltipState.open &&
      !tooltipState.edit &&
      tooltipState.highlight &&
      tooltipState.highlight.position.pageNumber === pageNumber
    ) {
      const {
        comment: { text, tags },
        position: { boundingRect },
      } = tooltipState.highlight!;
      return (
        <TooltipHover
          comment={text}
          tags={tags}
          refBbox={scaler.upscale(boundingRect)}
        />
      );
    }
  }, [
    TooltipHover,
    tooltipState.open,
    tooltipState.edit,
    tooltipState.highlight,
    pageNumber,
    scaler,
  ]);

  const tooltip = useMemo(() => {
    if (
      Tooltip &&
      tooltipState.open &&
      tooltipState.edit &&
      tooltipState.highlight &&
      tooltipState.highlight.position.pageNumber === pageNumber
    ) {
      return (
        <Tooltip
          highlight={tooltipState.highlight}
          refBbox={scaler.upscale(tooltipState.highlight.position.boundingRect)}
          close={() => {
            setFocus('text');
            dispatchTooltipState({
              type: 'close',
            });
            setGhostHighlight(undefined);
          }}
        />
      );
    }
  }, [
    Tooltip,
    tooltipState.open,
    tooltipState.edit,
    tooltipState.highlight,
    pageNumber,
    scaler,
    setFocus,
    dispatchTooltipState,
    setGhostHighlight,
  ]);

  // Render each of the highlights
  const highlightsRendered = useMemo(
    () =>
      highlights.map(h => {
        const isFocused = selectedHighlights.includes(h.id);
        const { rects, boundingRect } = h.position;
        const upscaledBoundingRect = scaler.upscale(boundingRect);

        const onMouseEnter = (e: any) => {
          const selection = window.getSelection();

          /* Only trigger event when 
          - we are not in edit mode
          - not selecting text
          - did not press the alt key
           */
          if (
            !tooltipState.edit &&
            (!selection || selection.isCollapsed) &&
            !e.altKey
          ) {
            dispatchTooltipState({
              type: 'show',
              highlight: h,
            });
          }
        };

        const onMouseLeave = (e: any) => {
          if (!tooltipState.edit) {
            setFocus('text');
            dispatchTooltipState({
              type: 'close',
            });
          }
        };

        // If we have an image attached, it is an area highlight
        if (h.content.image) {
          // Area highlight coordinates only can be updated
          const onChange = (newDimensions: BoundingBox) => {
            setGhostHighlight(undefined);

            const updatedHighlight = {
              ...h,
              position: {
                boundingRect: scaler.downscale(newDimensions),
                rects: [],
                pageNumber: h.position.pageNumber,
              },
            };

            dispatchHighlights!({
              type: 'update',
              ids: [h.id],
              update: updatedHighlight,
            });
            dispatchTooltipState({
              type: 'edit',
              highlight: updatedHighlight,
            });
          };

          return (
            <AreaHighlight
              key={h.id}
              highlight={h}
              isFocused={isFocused}
              bbox={upscaledBoundingRect}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onChange={onChange}
            />
          );
        } else {
          return (
            <TextHighlight
              key={h.id}
              rects={rects.map(scaler.upscale)}
              highlight={h}
              isFocused={isFocused}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          );
        }
      }),
    [
      highlights,
      selectedHighlights,
      scaler,
      tooltipState.edit,
      dispatchTooltipState,
      setFocus,
      setGhostHighlight,
      dispatchHighlights,
    ]
  );

  // Initiate the ghost area whenever the user uses alt + left click
  // Note: this will only be triggered when focus === "highlight"
  // because otherwise the layer has width=0 & height=0
  const onMouseDown = useCallback(
    (e: any) => {
      if (e.altKey) {
        const { offsetX, offsetY } = getOffset(e, pageRef.current);

        setGhostArea({
          x0: offsetX,
          y0: offsetY,
        });

        e.stopPropagation();
      }
    },
    [setGhostArea]
  );

  // Update the ghost area whenever the mouse is moved and there is
  // an initial area
  const onMouseMove = useCallback(
    (e: any) => {
      if (ghostArea) {
        const { offsetX, offsetY } = getOffset(e, pageRef.current);
        const { x0, y0 } = ghostArea;

        const x = Math.min(x0, offsetX);
        const y = Math.min(y0, offsetY);
        const width = Math.abs(offsetX - x0);
        const height = Math.abs(offsetY - y0);

        setGhostArea({
          x0,
          y0,
          x,
          y,
          width,
          height,
        });
      } else if (selectedHighlights.length === 1) {
      }
    },
    [ghostArea, setGhostArea, selectedHighlights]
  );

  const onClick = useCallback(
    (e: any) => {
      if (ghostArea) {
        selectOrCreateGhostArea(ghostArea);
      } else if (pageRef.current && e.target === pageRef.current) {
        setFocus('text');
        dispatchSelection({ type: 'clear' });
      }
      e.stopPropagation();
    },
    [ghostArea, setFocus, dispatchSelection, selectOrCreateGhostArea]
  );

  useEffect(() => {
    if (!ghostHighlight || ghostHighlight.pageNumber !== pageNumber) {
      return;
    }

    const highlight = {
      id: getId(),
      position: {
        rects: ghostHighlight.rects.map(scaler.downscale) as BoundingBox[],
        boundingRect: scaler.downscale(ghostHighlight.boundingRect),
        pageNumber: ghostHighlight.pageNumber,
      },
      comment: {
        text: '',
        tags: [],
      },
      content: {
        text: ghostHighlight.text,
      },
    };

    dispatchTooltipState({
      type: 'edit',
      highlight,
    });
  }, [ghostHighlight, scaler, pageNumber, dispatchTooltipState]);

  const mouseEvents = {
    onMouseDown,
    onMouseMove,
    onClick,
  };

  return (
    <StyledHighlightLayer focused={focused} ref={pageRef} {...mouseEvents}>
      {highlightsRendered}
      {ghostArea && (
        <StyledSelection
          style={{
            top: ghostArea.y,
            left: ghostArea.x,
            width: ghostArea.width,
            height: ghostArea.height,
            pointerEvents: 'none',
          }}
        />
      )}
      {ghostHighlight &&
        ghostHighlight.rects.map((bb, i) => (
          <StyledSelection
            key={i}
            style={{
              top: bb.y,
              left: bb.x,
              width: bb.width,
              height: bb.height,
            }}
          />
        ))}
      {tooltip}
      {tooltipHover}
    </StyledHighlightLayer>
  );
};
