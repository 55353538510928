import useResizeObserver from '@react-hook/resize-observer';
import { debounce } from 'lodash';
import { getDocument } from 'pdfjs-dist';
import { PDFDocumentProxy } from 'pdfjs-dist/types/display/api';
import 'pdfjs-dist/web/pdf_viewer.css';
import { useCallback, useEffect, useRef, useState } from 'react';

const {
  EventBus,
  PDFFindController,
  PDFLinkService,
  PDFViewer,
} = require('pdfjs-dist/web/pdf_viewer');
/**
 * Types definitions
 */

type Props = {
  pdfSource: any;
};

/**
 * Component
 */

export function useDocument({ pdfSource }: Props) {
  const [viewer, setViewer] = useState<any>(null);
  const [linkService, setLinkService] = useState<any>(null);
  const [pdfFindController, setPdfFindController] = useState<
    typeof PDFFindController
  >();

  // Pdf document
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  //   // Manually keep trace of the previous document
  //   const prevPdfDocument = usePrevious(pdfDocument);
  const containerRef = useRef<HTMLDivElement>(null);

  // To be called to fit the viewer to its parent
  const handleScaleValue = useCallback(() => {
    if (viewer && viewer.renderingQueue.printing === false) {
      viewer.currentScaleValue = 'auto';
    }
  }, [viewer]);

  //
  const handleScaleDebounced = useCallback(debounce(handleScaleValue, 300), [
    handleScaleValue,
  ]);

  useResizeObserver(containerRef, handleScaleDebounced);

  // Initiate the viewer
  useEffect(() => {
    if (containerRef.current && !viewer) {
      // Use pdf.js variables to handle events and others
      const eventBus = new EventBus();
      const linkService = new PDFLinkService();
      const pdfFindController = new PDFFindController({
        eventBus,
        linkService,
      });

      // Actual pdf viewer
      const viewer = new PDFViewer({
        container: containerRef.current,
        enhanceTextSelection: true,
        removePageBorders: true,
        linkService,
        eventBus,
      });

      // Save pdf.js variables in the state
      setViewer(viewer);
      setLinkService(linkService);
      setPdfFindController(pdfFindController);
    }
  }, [pdfDocument, viewer]);

  // Load pdf once the pdfSource changes
  useEffect(() => {
    setPdfDocument(undefined);

    if (pdfSource) {
      getDocument(pdfSource).promise.then(setPdfDocument);
    }
  }, [pdfSource, setPdfDocument]);

  useEffect(() => {
    // If there's no viewer loaded
    if (!viewer) {
      return;
    }

    // If the document changed, set the new document in the viewer
    viewer.setDocument(pdfDocument);
    linkService.setDocument(pdfDocument);
    linkService.setViewer(viewer);
  }, [linkService, pdfDocument, viewer]);

  return { viewer, linkService, pdfFindController, containerRef };
}
