import { GhostHighlight, Highlight, HighlightsPerPage } from '@src/types';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { PopupState } from 'src/types';

export const DocumentContext = React.createContext<{
  highlightsPerPage: HighlightsPerPage;
  selectedHighlights: string[];
  dispatchSelection: Function;
  tooltipState: PopupState;
  dispatchTooltipState: Function;
  setFocus: Function;
  ghostHighlight?: GhostHighlight;
  setGhostHighlight: Function;
  dispatchHighlights: Function;
  focus: string;
  Tooltip?: any;
  TooltipHover?: any;
  styleHighlight?: (h: Highlight) => CSSProperties;
}>({
  highlightsPerPage: {},
  selectedHighlights: [],
  dispatchSelection: () => {},
  setGhostHighlight: () => {},
  dispatchHighlights: () => {},
  setFocus: () => {},
  tooltipState: {
    edit: false,
    open: false,
  },
  dispatchTooltipState: () => {},
  focus: 'text',
});
