import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TooltipHoverProps } from '../../types';

/**
 * Types definitions
 */

type ExtendedProps = {
  isAbove: boolean;
};

/**
 * Styling
 */

const color = 'rgb(55, 55, 55)';

const StyledTooltip = styled.div<ExtendedProps>`
  position: absolute;
  max-width: 300px;
  box-shadow: ${({ isAbove }: any) =>
    isAbove ? '0.5px 1px grey' : '-0.5px -1px grey'};
  border-radius: 4px;
  background: ${color};
  color: #fff;
  transition: opacity 200ms ease;

  &:hover {
    cursor: pointer;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    justify-content: space-around;
    margin: 0 0.75rem 0.75rem;
  }

  .comment,
  .tags {
    margin-top: 0.75rem;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tag {
    background: white;
    border-radius: 4px;
    padding: 2px 4px;
    margin-right: 2px;
    color: black;
  }

  /* Arrow at the top or below */
  &:after {
    content: ' ';
    position: absolute;
    ${({ isAbove }: any) => (isAbove ? 'top: 100%;' : 'top: 0%;')}
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: ${({ isAbove }: any) =>
      isAbove
        ? `${color} transparent transparent transparent`
        : `transparent transparent ${color} transparent`};
  }
`;

/**
 * Component
 */

export function TooltipHover({ comment, tags, refBbox }: TooltipHoverProps) {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isAbove, setIsAbove] = useState(true);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPosition({ top: 0, left: 0 });
    if (tooltipRef.current) {
      let top = refBbox.y - tooltipRef.current.offsetHeight - 10;
      const left =
        refBbox.x + refBbox.width / 2 - tooltipRef.current.offsetWidth / 2;

      const refPdfHighlighter = tooltipRef.current.closest('.PdfHighlighter');
      const refPage = tooltipRef.current.closest('.page') as HTMLDivElement;
      if (
        refPdfHighlighter &&
        refPage &&
        top - refPdfHighlighter.scrollTop + refPage.offsetTop < 0
      ) {
        top = refBbox.y + refBbox.height + 10;
        setIsAbove(false);
      } else {
        setIsAbove(true);
      }

      setPosition({ top, left });
    }
  }, [refBbox]);

  return (
    <StyledTooltip
      isAbove={isAbove}
      style={
        position.top && position.left && (comment || tags.length > 0)
          ? position
          : { opacity: 0 }
      }
      ref={tooltipRef}
    >
      <div className="content">
        {tags && tags.length > 0 && (
          <div className="tags">
            {tags.map((tag, i) => (
              <span className="tag" key={i}>
                {tag}
              </span>
            ))}
          </div>
        )}
        {comment && <div className="comment">{comment}</div>}
      </div>
      {/* <div className="arrow" /> */}
    </StyledTooltip>
  );
}
