import { BoundingBox } from '@src/types';

const sort = (rects: BoundingBox[]) =>
  rects.sort((A, B) => {
    const y = A.y - B.y;

    if (y === 0) {
      return A.x - B.x;
    }

    return y;
  });

const overlaps = (A: BoundingBox, B: BoundingBox) =>
  A.x <= B.x && B.x <= A.x + A.width;

const sameLine = (A: BoundingBox, B: BoundingBox, yMargin = 5) =>
  Math.abs(A.y - B.y) < yMargin && Math.abs(A.height - B.height) < yMargin;

const inside = (A: BoundingBox, B: BoundingBox) =>
  A.y > B.y &&
  A.x > B.x &&
  A.y + A.height < B.y + B.height &&
  A.x + A.width < B.x + B.width;

const nextTo = (A: BoundingBox, B: BoundingBox, xMargin = 10) => {
  const Aright = A.x + A.width;
  const Bright = B.x + B.width;

  return A.x <= B.x && Aright <= Bright && B.x - Aright <= xMargin;
};

const extendWidth = (A: BoundingBox, B: BoundingBox) => {
  // extend width of A to cover B
  A.width = Math.max(B.width - A.x + B.x, A.width);
};

const optimizeClientRects = (clientRects: BoundingBox[]): BoundingBox[] => {
  const rects = sort(clientRects);

  const toRemove = new Set();

  const firstPass = rects.filter(rect => {
    return rects.every(otherRect => {
      return !inside(rect, otherRect);
    });
  });

  let passCount = 0;

  while (passCount <= 2) {
    firstPass.forEach(A => {
      firstPass.forEach(B => {
        if (A === B || toRemove.has(A) || toRemove.has(B)) {
          return;
        }

        if (!sameLine(A, B)) {
          return;
        }

        if (overlaps(A, B)) {
          extendWidth(A, B);
          A.height = Math.max(A.height, B.height);

          toRemove.add(B);
        }

        if (nextTo(A, B)) {
          extendWidth(A, B);

          toRemove.add(B);
        }
      });
    });
    passCount += 1;
  }

  return firstPass.filter(rect => !toRemove.has(rect));
};

export default optimizeClientRects;
