import React from 'react';

export type Props = {
  pct: number;
  dark?: boolean;
};

const GaugeStandard = ({ pct, dark }: Props) => {
  const textColor = dark ? 'text-white' : 'text-grey';

  return (
    <div className="flex space-x-2">
      {/* We use two half-circle  */}
      <svg
        width={38}
        height={38}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Gray circle */}
        <circle
          className="ring"
          cx={19}
          cy={19}
          r={15.91549430918954}
          fill="transparent"
          stroke={dark ? 'lightgray' : 'gray'}
          strokeWidth={2}
          strokeDashoffset="50"
          strokeDasharray="50"
        />
        {/* Colored circle */}
        <circle
          className="ring"
          cx={19}
          cy={19}
          r={15.91549430918954}
          stroke="url(#paint0_linear)"
          fill="none"
          strokeDashoffset={25 + 50 * pct}
          strokeWidth={3}
          strokeDasharray={`${50 * pct} 75`}
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1.17998"
            y1="11.5594"
            x2="9.28047"
            y2="11.4245"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0188CB" />
            <stop offset="1" stopColor="#07C4DA" />
          </linearGradient>
        </defs>
      </svg>
      <div className={textColor}>{(pct * 100).toFixed()}</div>
    </div>
  );
};

GaugeStandard.defaultProps = {
  dark: false,
};

export default GaugeStandard;
