import { HighlightLayer } from '@src/components/HighlightLayer';
import { getScaler } from '@src/lib/coordinates';
import { getId } from '@src/lib/generic';
import getBoundingRect from '@src/lib/get-bounding-rect';
import getClientRects from '@src/lib/get-client-rects';
import { findOrCreateContainerLayer } from '@src/lib/pdfjs-dom';
import {
  GhostHighlight,
  Highlight,
  HighlightAction,
  HighlightsPerPage,
} from '@src/types';
import { PDFDocumentProxy } from 'pdfjs-dist/types/display/api';
import 'pdfjs-dist/web/pdf_viewer.css';
import React, {
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { usePrevious } from 'react-delta';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import { SelectionAction } from '../../types';
import useControlledReducer from '../custom_hooks/useControlledReducer';
import { DocumentContext } from './Context';
import { highlightsReducer, popupReducer, selectionReducer } from './reducers';
import { useDocument } from './useDocument';

/**
 * Types definitions
 */

type Props = {
  rotate?: number;
  scale?: number;
  pageNo?: number;
  pdfSource: PDFDocumentProxy;
  selectedHighlights?: string[];
  dispatchSelection?: (action: SelectionAction) => void;
  highlights: Highlight[];
  setHighlights: (highlights: Highlight[]) => void;
  Tooltip?: ElementType;
  TooltipHover?: ElementType;
  styleHighlight?: (h: Highlight) => React.CSSProperties;
  onPageChange?: (pageNo: number) => void;
};

type ExtendedProps = {
  textSelectable: boolean;
};

/**
 * Styling
 */

const StyledPdfHighlighter = styled.div<ExtendedProps>`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  && {
    .page {
      border: 0 1px solid black;
    }
  }
  .textLayer {
    ${({ textSelectable }: any) => (textSelectable ? '' : 'user-select: none;')}
    z-index: 2;
    opacity: 1;
    mix-blend-mode: multiply;
  }
  .annotationLayer {
    position: absolute;
    top: 0;
    z-index: 3;
  }
  .textLayer
    > div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
    opacity: 1;
    mix-blend-mode: multiply;
  }
  .textLayer ::selection {
    background: rgba(252, 232, 151, 1);
    mix-blend-mode: multiply;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {
      opacity: 0.5;
    }
  }

  /* Internet Explorer support method */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {
      opacity: 0.5;
    }
  }

  /* Microsoft Edge Browser 12+ (All) - @supports method */
  @supports (-ms-ime-align: auto) {
    .textLayer {
      opacity: 0.5;
    }
  }
`;

/**
 * Component
 */

export function Document({
  pdfSource,
  highlights,
  setHighlights,
  Tooltip,
  TooltipHover,
  pageNo,
  selectedHighlights: controlledSelectedHighlights,
  dispatchSelection: controlledDispatchSelection,
  styleHighlight,
  onPageChange: controlledOnPageChange,
}: Props): ReactNode {
  const [focus, setFocus] = useState<'text' | 'highlight' | 'edit'>('text');

  // Reducer to keep trace of selected highlights
  const [selectedHighlights, dispatchSelection] = useControlledReducer(
    selectionReducer,
    controlledSelectedHighlights ?? [],
    controlledDispatchSelection
  );

  const dispatchHighlights = useCallback(
    (action: HighlightAction) => {
      setHighlights(highlightsReducer(highlights, action));
    },
    [highlights, setHighlights]
  );
  const prevHighlights = usePrevious(highlights);

  // Ghosthighlight represents a temporary highlight that was not added/saved yet
  const [ghostHighlight, setGhostHighlight] = useState<
    GhostHighlight | undefined
  >();

  // Elements rendered using a react portal
  const [highlightsElements, setHighlightsElements] = useState<any>();

  // Reducer for the popup state
  const [tooltipState, dispatchTooltipState] = useReducer(popupReducer, {
    edit: false,
    open: false,
  });

  // Handle the document viewing
  const { viewer, linkService, containerRef } = useDocument({
    pdfSource,
  });

  const scrollToPage = useCallback(() => {
    if (
      pageNo !== undefined &&
      viewer &&
      pageNo in viewer._pages &&
      pageNo !== viewer._currentPageNumber
    ) {
      viewer._pages[pageNo - 1].div.scrollIntoView({});
    }
  }, [pageNo, viewer]);

  useEffect(() => {
    scrollToPage();
  }, [scrollToPage]);

  // Force the focus to be on the text if there is nothing selected
  useEffect(() => {
    if (selectedHighlights.length === 0) {
      setFocus('text');
    }
  }, [selectedHighlights, setFocus]);

  // Aggregate highlights for each page
  const highlightsPerPage: HighlightsPerPage = useMemo(
    () =>
      highlights
        .filter(Boolean)
        .reduce((res: HighlightsPerPage, highlight: Highlight) => {
          const { pageNumber } = highlight.position;

          res[pageNumber] = res[pageNumber] || [];
          res[pageNumber].push(highlight);

          return res;
        }, {}),
    [highlights]
  );

  // pdfjs triggers an event each time a page is rendered;
  // We use this event (below) to call the rendering and save it in
  // the state
  const renderHighlightsForPage = useCallback(
    ({ source, pageNumber }) => {
      const { div: pageDiv, viewport } = source;

      // Try to get the highlight layer, if not possible, we skip this page
      const highlightLayer = findOrCreateContainerLayer(
        pageDiv,
        'react-pdf__highlight-layer'
      );
      if (!highlightLayer) {
        return;
      }

      // Get the scaler that will be used for the highlights on the page
      const scaler = getScaler({
        x: viewport.scale * viewport.viewBox[2],
        y: viewport.scale * viewport.viewBox[3],
      });

      const newHighlightsElements = { ...highlightsElements };

      newHighlightsElements[pageNumber] = ReactDom.createPortal(
        <HighlightLayer
          key={pageNumber}
          pageNumber={pageNumber}
          rotate={0}
          scaler={scaler}
        />,
        highlightLayer
      );

      setHighlightsElements(newHighlightsElements);
    },
    [highlightsElements, setHighlightsElements]
  );

  const onDocumentReady = useCallback(() => {
    if (!viewer) {
      return;
    }

    viewer.currentScaleValue = 'auto';

    scrollToPage();
  }, [viewer, scrollToPage]);

  // Call custom on page change whenever the page changes
  const onPageChange = useCallback(
    (e: any) => {
      if (controlledOnPageChange) {
        controlledOnPageChange(e.pageNumber);
      }
    },
    [controlledOnPageChange]
  );

  useEffect(() => {
    // If there's no viewer loaded
    if (!viewer) {
      return;
    }

    viewer.eventBus.on('pagesinit', onDocumentReady);
    viewer.eventBus.on('pagerendered', renderHighlightsForPage);
    viewer.eventBus.on('pagechanging', onPageChange);

    return () => {
      viewer.eventBus.off('pagesinit', onDocumentReady);
      viewer.eventBus.off('pagerendered', renderHighlightsForPage);
      viewer.eventBus.off('pagechanging', onPageChange);
    };
  }, [
    viewer,
    linkService,
    onDocumentReady,
    renderHighlightsForPage,
    onPageChange,
  ]);

  // Handle keyboard events
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Shift':
          setFocus('highlight');
          break;
        case 'Alt':
          setFocus('highlight');
          break;
        case 'Escape':
          dispatchSelection({ type: 'clear' });
          setGhostHighlight(undefined);
          break;
        case 'Delete':
          dispatchHighlights({ type: 'delete', ids: selectedHighlights });
          dispatchSelection({ type: 'clear' });
          break;
        case 'ArrowDown':
          if (e.shiftKey) {
            dispatchHighlights({
              type: 'resize',
              ids: selectedHighlights,
              y: +0.005,
              x: 0,
            });
          } else {
            dispatchHighlights({
              type: 'move',
              ids: selectedHighlights,
              y: +0.005,
              x: 0,
            });
          }
          e.preventDefault();
          break;
        case 'ArrowUp':
          if (e.shiftKey) {
            dispatchHighlights({
              type: 'resize',
              ids: selectedHighlights,
              y: -0.005,
              x: 0,
            });
          } else {
            dispatchHighlights({
              type: 'move',
              ids: selectedHighlights,
              y: -0.005,
              x: 0,
            });
          }

          e.preventDefault();
          break;
        case 'ArrowRight':
          if (e.shiftKey) {
            dispatchHighlights({
              type: 'resize',
              ids: selectedHighlights,
              x: +0.01,
              y: 0,
            });
          } else {
            dispatchHighlights({
              type: 'move',
              ids: selectedHighlights,
              x: +0.01,
              y: 0,
            });
          }
          e.preventDefault();
          break;
        case 'ArrowLeft':
          if (e.shiftKey) {
            dispatchHighlights({
              type: 'resize',
              ids: selectedHighlights,
              x: -0.01,
              y: 0,
            });
          } else {
            dispatchHighlights({
              type: 'move',
              ids: selectedHighlights,
              x: -0.01,
              y: 0,
            });
          }
          e.preventDefault();
          break;
        case 'd':
          if (e.ctrlKey) {
            const newIds = selectedHighlights.map(() => getId());
            dispatchHighlights({
              type: 'duplicate',
              ids: selectedHighlights,
              newIds,
            });
            dispatchSelection({ type: 'clear' });
            dispatchSelection({ type: 'force-select', ids: newIds });
            e.preventDefault();
          }
          break;
        case 'z':
          if (e.ctrlKey) {
            dispatchHighlights({
              type: 'set',
              newState: prevHighlights as Highlight[],
            });
            e.preventDefault();
          }
          break;
      }
    },
    [
      setGhostHighlight,
      dispatchHighlights,
      selectedHighlights,
      dispatchSelection,
      prevHighlights,
    ]
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Shift':
          setFocus('text');
          break;
        case 'Alt':
          setFocus('text');
          break;
      }
    },
    [setFocus]
  );

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp);

    const closing: Function[] = [
      () => document.removeEventListener('keyup', onKeyUp),
    ];
    if (['text', 'highlight'].includes(focus)) {
      document.addEventListener('keydown', onKeyDown);
      closing.push(() => document.removeEventListener('keydown', onKeyDown));
    }

    return () => {
      closing.forEach(close => close());
    };
  }, [onKeyDown, onKeyUp, focus]);

  const onMouseDown = useCallback(
    (e: any) => {
      if (
        e.target.classList.contains('textLayer') ||
        e.target.tagName === 'SPAN'
      ) {
        setGhostHighlight && setGhostHighlight(undefined);
        dispatchSelection({ type: 'clear' });
      }
    },
    [dispatchSelection, setGhostHighlight]
  );

  const onMouseUp = useCallback((e: any) => {
    if (e.altKey) {
      return;
    }

    // Get the current text selection by the user
    const selection = window.getSelection();

    if (!selection || selection.isCollapsed) {
      return;
    }

    const range = selection.getRangeAt(0);

    if (!range) {
      return;
    }

    let pageRef = range.commonAncestorContainer.parentElement?.closest('.page');

    if (pageRef) {
      // Get bounding boxes of the selection
      const rects = getClientRects(range, pageRef as HTMLDivElement);
      const boundingRect = getBoundingRect(rects);

      if (boundingRect) {
        // Retrieve the page number of which the event occured
        const pageNumber = parseInt(
          pageRef.getAttribute('data-page-number') as string
        );

        // Set the temporary highlight
        setGhostHighlight({
          rects,
          boundingRect,
          pageNumber,
          text: selection.toString(),
        });
      }
    }

    selection.removeAllRanges();
  }, []);

  const mouseEvents = {
    onMouseUp,
    onMouseDown,
  };

  return (
    <DocumentContext.Provider
      // Context
      value={{
        tooltipState,
        dispatchTooltipState,
        highlightsPerPage,
        selectedHighlights,
        dispatchSelection,
        setFocus,
        ghostHighlight,
        setGhostHighlight,
        dispatchHighlights,
        focus,
        Tooltip,
        TooltipHover,
        styleHighlight,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: 'calc(100% - 2px)',
          height: '100%',
        }}
      >
        <StyledPdfHighlighter
          textSelectable={focus === 'text'}
          ref={containerRef}
          className="PdfHighlighter"
          {...mouseEvents}
        >
          <div className="pdfViewer" onContextMenu={e => e.preventDefault()} />
          {viewer &&
            highlightsElements &&
            Object.keys(highlightsElements).map(key => highlightsElements[key])}
        </StyledPdfHighlighter>
      </div>
    </DocumentContext.Provider>
  );
}
