import { Transition } from '@tailwindui/react';
import React, { useEffect, useRef, useState } from 'react';

export type Props = {
  label: string;
  options: {
    label: string;
  }[];
  selected: string[];
  onSelect: (label: string) => void;
  dark?: boolean;
  multi?: boolean;
};

const Select = ({ label, options, selected, onSelect, dark, multi }: Props) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const textColor = dark ? 'text-white' : 'text-grey';
  const bgColor = dark ? 'bg-grey' : 'bg-white';

  // Unfocus on click outside
  useEffect(() => {
    if (focused) {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setFocused(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return;
  }, [focused, setFocused]);

  return (
    <div className="space-y-1" ref={ref}>
      <label
        id="listbox-label"
        className={`${textColor} block text-sm md:text-base leading-5 font-medium`}
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          className={`${bgColor} ${textColor} relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
          style={{ borderColor: 'rgb(107, 114, 128)' }}
          onClick={() => setFocused(!focused)}
        >
          <span className="block truncate">
            {selected.length > 0 ? selected.join(', ') : '\u00A0'}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
        {/* Panel with options */}
        <Transition
          show={focused}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-all duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`${bgColor} absolute mt-1 w-full rounded-md shadow-xl`}
          >
            <ul
              tabIndex={-1}
              role="listbox"
              className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >
              {options.map(({ label }) => {
                const isSelected = selected.includes(label);

                return (
                  <li
                    id={label}
                    role="option"
                    aria-selected={isSelected}
                    onClick={() => {
                      if (!multi) {
                        setFocused(false);
                      }
                      onSelect(label);
                    }}
                    className={`${
                      isSelected
                        ? 'text-white bg-lochmara-600 hover:bg-lochmara-400'
                        : `${textColor} hover:bg-lochmara-300`
                    } cursor-pointer  select-none relative py-2 pl-8 pr-4`}
                  >
                    {/* Indicator of selected value */}
                    {isSelected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-1">
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                    <span className="font-normal block truncate">{label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  );
};

Select.defaultProps = {
  dark: false,
  multi: true,
};

export default Select;
