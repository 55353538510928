import { BoundingBox, Scaler } from '../types';

export const capPosition = (pos: number, len: number): [number, number] => {
  const pos0 = Math.max(0, Math.min(0.97, pos));
  const pos1 = Math.max(0.03, Math.min(1, pos + len));

  return [pos0, pos1 - pos0];
};

export const getScaler = (scale: { x: number; y: number }): Scaler => ({
  upscale: ({ x, y, width, height }: BoundingBox): BoundingBox => ({
    x: x * scale.x,
    y: y * scale.y,
    width: width * scale.x,
    height: height * scale.y,
  }),
  downscale: (BB: BoundingBox): BoundingBox => {
    const [x, width] = capPosition(BB.x / scale.x, BB.width / scale.x);
    const [y, height] = capPosition(BB.y / scale.y, BB.height / scale.y);

    return {
      x,
      y,
      width,
      height,
    };
  },
});

export const isContained = (
  rect: BoundingBox,
  childRect: BoundingBox,
  margin = 0.01
): boolean => {
  /**
   * Test if childRect is contained in rect. Allow to include a margin.
   */

  return (
    rect.x - margin <= childRect.x &&
    rect.x + rect.width + margin * 1.5 >= childRect.x + childRect.width &&
    rect.y - margin <= childRect.y &&
    rect.y + rect.height + margin >= childRect.y + childRect.height
  );
};

/**
 * Test if rect0 is above rect1 (reading order top-left to bottom-right)
 * @param rect0 Rectangle that should be above rect1
 * @param rect1 Rectangle that should be below rect0
 */
export const isAbove = (
  rect0: BoundingBox,
  rect1: BoundingBox,
  margin = 0.005
): boolean => {
  const yDiff = rect1.y - rect0.y;

  // Test if they are approximately on the same vertical height
  if (yDiff < margin && yDiff > -margin) {
    return rect0.x < rect0.y;
  } else if (yDiff > 0) {
    return true;
  } else {
    return false;
  }
};

type Boundary = HTMLElement | null | Function;

export const getOffset = (e: any, boundary: Boundary = null) => {
  let testCondition: Function;
  if (boundary instanceof Function) {
    testCondition = (el: HTMLElement) => el && boundary(el);
  } else if (boundary) {
    testCondition = (el: HTMLElement) => el && el === boundary;
    const target = e.target.getBoundingClientRect();
    const ref = boundary.getBoundingClientRect();
    return {
      offsetX: target.x - ref.x + e.nativeEvent.offsetX,
      offsetY: target.y - ref.y + e.nativeEvent.offsetY,
      parent: boundary,
    };
  } else {
    testCondition = (el: HTMLElement) => !!el;
  }

  let { offsetX, offsetY } = e.nativeEvent;

  let parent = e.nativeEvent.target;

  // offsetX += parent.offsetLeft;
  // offsetY += parent.offsetTop;

  while (!testCondition(parent)) {
    offsetX += parent.offsetLeft;
    offsetY += parent.offsetTop;
    parent = parent.parentElement as HTMLElement;
  }

  return { offsetX, offsetY, parent };
};
