import { BoundingBox, Highlight } from '@src/types';
import React, { useCallback, useContext, useMemo } from 'react';
import { Rnd } from 'react-rnd';
import styled from 'styled-components';
import { DocumentContext } from '../Document/Context';

/**
 * Types definitions
 */

type Props = {
  // highlight: T_ViewportHighlight;
  bbox: BoundingBox;
  onChange?: (rect: BoundingBox) => void;
  onClick?: (e: any) => void;
  isFocused?: boolean;
  highlight: Highlight;
} & Partial<Rnd['props']>;

/**
 * Styling
 */

const StyledRnd = styled(Rnd)`
  ${({ focused }: { focused: boolean }) =>
    focused ? 'border: 3px dashed #1C6EA4;' : ''}
  opacity: ${({ focused }: { focused: boolean }) => (focused ? '0.8' : '0.4')};
  background-color: rgb(255, 226, 143);
  transition: background 0.2s;
`;

/**
 * Component
 */

export const AreaHighlight = ({
  bbox,
  isFocused,
  onChange,
  // onClick,
  highlight,
  ...otherProps
}: Props) => {
  const {
    styleHighlight,
    dispatchTooltipState,
    dispatchSelection,
  } = useContext(DocumentContext);

  const onDragStop = useCallback(
    (_, data) => {
      const newBbox: BoundingBox = {
        width: bbox.width,
        height: bbox.height,
        x: data.x,
        y: data.y,
      };

      // Only update if origin (x, y) changed
      if (newBbox.x !== bbox.x || newBbox.y !== bbox.y) {
        onChange!(newBbox);
      }
    },
    [onChange, bbox]
  );

  const onResizeStop = useCallback(
    (_, direction, ref, delta, position) => {
      const newBbox: BoundingBox = {
        y: position.y,
        x: position.x,
        width: bbox.width + delta.width,
        height: bbox.height + delta.height,
      };

      // Only update if anything changed
      if (
        newBbox.width !== bbox.width ||
        newBbox.height !== bbox.height ||
        newBbox.x !== bbox.x ||
        newBbox.y !== bbox.y
      ) {
        onChange!(newBbox);
      }
    },
    [onChange, bbox]
  );

  // Apply user-defined style from a function if available
  const style = useMemo(() => {
    if (styleHighlight) {
      return styleHighlight(highlight);
    }
  }, [highlight, styleHighlight]);

  const onClick = (e: React.MouseEvent) => {
    dispatchTooltipState({
      type: 'edit',
      highlight,
    });

    dispatchSelection({
      type: 'toggle',
      id: highlight.id,
      e: e.nativeEvent,
    });
  };

  return (
    <StyledRnd
      style={style}
      focused={isFocused}
      disableDragging={!isFocused}
      enableResizing={isFocused}
      onDragStop={onDragStop}
      onResizeStop={isFocused ? onResizeStop : undefined}
      position={{
        x: bbox.x,
        y: bbox.y,
      }}
      size={{
        width: bbox.width,
        height: bbox.height,
      }}
      onClick={(e: React.MouseEvent) => {
        if (!isFocused) {
          onClick(e);
        }
      }}
      {...otherProps}
    />
  );
};
