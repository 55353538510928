import React, { ReactNode } from 'react';
import '@src/assets/main.css';
import { Color, Theme, Size } from '@src/types';

export type Props = {
  text?: string;
  action?: () => void;
  theme?: Theme;
  id?: string;
  size: Size;
  uppercase?: boolean;
  fullWidth?: boolean;
  prefix?: ReactNode;
  postfix?: ReactNode;
  submit?: boolean;
  children?: ReactNode | undefined;
};

const sizeToClass = (size: Size) => {
  switch (size) {
    case 'sm':
      return ' py-2 px-4';
    case 'md':
      return ' py-5 px-8';
  }
};

// We are using this instead of concatenating the color in order to avoid the styles to be purged by postcss.
const colorToBgClass = (color: Color) => {
  if (color === 'gradient-light' || color === 'gradient-cta') {
    return `${color}-hover ${color}`;
  } else {
    return `bg-${color}-500 hover:bg-${color}-600`;
  }
};

const SimpleButton = ({
  text,
  action,
  theme,
  id,
  uppercase,
  size,
  fullWidth,
  prefix,
  postfix,
  children,
  submit,
}: Props) => {
  const color = theme && theme.variant ? theme.variant : 'darkturquoise';

  let className = `overflow-ellipsis overflow-hidden rounded-full flex items-center justify-center `;
  className += sizeToClass(size);
  className += uppercase ? ' uppercase' : '';
  className += fullWidth === undefined || fullWidth ? ' w-full' : '';
  className += ' text-white ' + colorToBgClass(color);
  className += ' button';

  return (
    <button
      id={id}
      type={submit ? 'submit' : 'button'}
      onClick={action}
      className={className}
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <>
          {prefix && <div className="mr-2 -ml-0.5 h-6 w-6">{prefix}</div>}
          {text && <div className="truncate">{text}</div>}
          {postfix && <div className="ml-2 -mr-0.5 h-6 w-6">{postfix}</div>}
        </>
      )}
    </button>
  );
};

SimpleButton.defaultProps = {
  uppercase: false,
  size: 'sm',
  theme: {
    variant: 'gradient-light',
    dark: false,
  },
  fullWidth: false,
  submit: false,
  action: () => {},
};

export default SimpleButton;
