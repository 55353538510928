import { BoundingBox } from '../types.js';

import optimizeClientRects from './optimize-client-rects';

const getClientRects = (
  range: Range,
  containerEl: HTMLElement,
  shouldOptimize: boolean = true
): BoundingBox[] => {
  let clientRects = Array.from(range.getClientRects());

  const offset = containerEl.getBoundingClientRect();

  const rects = clientRects.map(rect => {
    return {
      y: rect.top + containerEl.scrollTop - offset.top,
      x: rect.left + containerEl.scrollLeft - offset.left,
      width: rect.width,
      height: rect.height,
    };
  });

  return shouldOptimize ? optimizeClientRects(rects) : rects;
};

export default getClientRects;
